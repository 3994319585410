import { AboutDescSectionProps } from "../../model";

export function DescriptionSection({ contents }: AboutDescSectionProps) {
  return (
    <section class="flex flex-col bg#paper items-center">
      <div class="flex flex-col gap-40px justify-center items-center my-80px">
        {contents.map((content) => {
          const descs = content.description.split("\n");
          return (
            <article class="w-845px mx-auto">
              <p class="text-25px font-bold leading-28px mb-10px">{content.title}</p>
              {descs.map((item) => {
                if (item === "" || item === "\n") {
                  return <br />;
                }
                return <p class="text-16px font-normal leading-24px">{item}</p>;
              })}
            </article>
          );
        })}
      </div>
      <hr class="max-w-screen w-screen h-2px bg#p" />
    </section>
  );
}
